import React, { useState, useContext, useEffect } from 'react';
import $ from 'jquery';
import { Row, Col, Button, Form, Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';

import PsContext from '../../../context';
import api from '../../../utils/api';

const EditPastorate=(props)=>{
	
	const context = useContext(PsContext);
	
	const [loader, setLoader] = useState(false);
	const [validated, setValidated] = useState(false);
	
	const handleSubmit=(e)=>{
		e.preventDefault();
		const form = e.currentTarget;
		if (form.checkValidity() === false) {
		  e.stopPropagation();
		  setValidated(true);
		  return;
		}
		
		setLoader(true);
		api.post('v1/pastorate/update', $("#frm_edit_pastorate").serialize()).then(res=>{
			if(res['data'].errorcode=='200'){
				toast.success(res['data'].content || 'Success');
				setLoader(false);
				
				if(props.afterFinish)
					props.afterFinish();
			}
			else{
				toast.error(res['data'].content || 'Error');
				setLoader(false);
			}
		});
	}
	
	const field=(f)=>{
		
		return props.data[f] || '';
	};
	
	return(
	<>
		<Form 
			noValidate 
			validated={validated} 
			id="frm_edit_pastorate"
			onSubmit={handleSubmit}
		>
			<div className="cb__modal__body" >
				<input type="hidden" name="api" value={context.state.api} />
				<input type="hidden" name="id" value={field('id')} />
				<Row>
					<Col md={12} >
						<label>Name of the Pastrorate <span className="text-danger">*</span> </label>
						<Form.Control
							type="text"
							size="md"
							name="pastorate_name"
							defaultValue={field('name')}
							autoFocus={true}
							required
						/>
					</Col>	
				</Row>
				
				<Row className="mt-3" >
					<Col md={12} >
						<label>Remarks <span className="text-danger"></span> </label>
						<Form.Control
							as="textarea"
							rows="4"
							name="remarks"
							defaultValue={field('remarks')}
						/>
					</Col>	
				</Row>
				
				<Row className="mt-3" >
					<Col md={12} >
						<label>Status <span className="text-danger">*</span> </label>
						<Form.Control
							as="select"
							name="status"
							required
						>
							<option value="1" selected={field('active_status')=='1' ? 'selected' : ''} > Active </option>
							<option value="0" selected={field('active_status')=='0' ? 'selected' : ''}  > In-Active </option>
						</Form.Control>
					</Col>	
				</Row>
				
			</div>
			<div className="cb__modal__footer" >
				<Row>
					<Col md={12} >
						<div className="text-right" >
							<Button
								type="submit"
								size="sm"
								variant="primary"
								disabled={loader}
							>
							{loader ? <>
								<Spinner animation="border" size="sm" /> <span className="ps-2 border-left">WAIT..</span>
							</> : <>
								<i className="icofont-check pe-2 border-right" ></i> UPDATE
							</>}
							</Button>
						</div>
					</Col>
				</Row>
			</div>
		</Form>
	</>
	);
};

export default EditPastorate;