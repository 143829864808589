import React from 'react';
import { Row, Col } from 'react-bootstrap';

import VerifyCard from '../membershipcard/verifyCard';

const Dashboard=()=>{
	
	return(
	<>
	
		<Row>
			<Col md={4} >
				<div className="panel panel-form" >
					<div className="panel-header">
						Verify Membership Card
					</div>
					
					<VerifyCard />
					
				</div>
			</Col>
		</Row>
	
	</>
	);
};

export default Dashboard;