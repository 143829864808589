import React, { Component } from 'react';
import $ from 'jquery';
import PsContext from './PsContext';
import {
    setlsItem, getlsItem, getls, lowerCase
} from '../utils';

// Then create a provider Component
class PsContextProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogged: getlsItem('isLogged') || 'no',
            api: getlsItem('loggedApi') || null,
            loggedApi: getlsItem('loggedApi') || null,
            loggedUser: getlsItem('loggedUser') ? JSON.parse(getlsItem('loggedUser')) : [],
            loggedCompany: getlsItem('loggedCompany') ? JSON.parse(getlsItem('loggedCompany')) : [],
            loggedPharmacy: getlsItem('loggedPharmacy') ? JSON.parse(getlsItem('loggedPharmacy')) : [],
			loggedAcyear: getlsItem('loggedAcyear') ? JSON.parse(getlsItem('loggedAcyear')) : [],
        };

      this.updateUserLogin = this.updateUserLogin.bind(this);
      this.updateCompany = this.updateCompany.bind(this);
      this.logout = this.logout.bind(this);
      this.api = this.api.bind(this);
      this.requestHeader = this.requestHeader.bind(this);
      this.setMainBodyP0 = this.setMainBodyP0.bind(this);
      this.updateLoggedAcyear = this.updateLoggedAcyear.bind(this);
	  
      this.updatePharmacy = this.updatePharmacy.bind(this);
      this.userAllowed = this.userAllowed.bind(this);
    }

    componentDidMount() {
      //$('body').addClass('sidebar-collapse');
    }

    updateUserLogin(userdata, api) {
        setlsItem('loggedUser', JSON.stringify(userdata));
        setlsItem('isLogged', 'YES');
        setlsItem('loggedApi', api);
        this.setState({ loggedUser: userdata, isLogged: 'YES', loggedApi: api, api: api,});
    }

    updateCompany(_com){
        setlsItem('loggedCompany', JSON.stringify(_com));	
        this.setState({loggedCompany: _com});		
    }
	
	 updateLoggedAcyear(_year){
        setlsItem('loggedAcyear', JSON.stringify(_year));	
        this.setState({loggedAcyear: _year});		
    }
	
	 updatePharmacy(_com){
        setlsItem('loggedPharmacy', JSON.stringify(_com));	
        this.setState({loggedPharmacy: _com});		
    }
    
    logout() {
        setlsItem('isLogged', 'no');
        setlsItem('loggedUser', '');
        setlsItem('loggedCompany', '');
        setlsItem('role', '');
        this.setState({ loggedUser: [], loggedCompany: [], isLogged: 'no' });
    }

    api(){
        return getlsItem('loggedApi');
    }

    requestHeader() {
        const d = {
          headers: {
            'Api-Key': getls('loginUser', 'api') || 'null',
          },
        };

      return d;
    }
    
    setMainBodyP0(){
        try{
            var el = document.getElementsByClassName("main-body");
            $(el[0]).addClass("p-0");
        }
        catch(error){ }	
    }
	
	userAllowed(roles){
		try{
			var role = this.state.loggedUser.role;
			
			return (roles.indexOf(lowerCase(role))>-1);
		}
		catch(er){
			return false;
		}
	}


    render() {
        return (
            <PsContext.Provider value={{
                state: this.state, 
                updateUserLogin: this.updateUserLogin, 
                requestHeader: this.requestHeader, 
                logout: this.logout, 
                updateCompany: this.updateCompany,
                updateLoggedAcyear: this.updateLoggedAcyear,
                updatePharmacy: this.updatePharmacy,
                setMainBodyP0: this.setMainBodyP0,
                api: this.api,
				
                userAllowed: this.userAllowed,
              }}
            >
              {this.props.children}
            </PsContext.Provider>
        );
    }
}

export default PsContextProvider;
