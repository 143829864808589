import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Modal, Spinner, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Avatar, Image } from 'antd';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ReactPaginate from 'react-paginate';

import PsContext from '../../../context';
import api from '../../../utils/api';
import { upperCase, momentDate, objectToQueryString } from '../../../utils';
import { Api } from '../../../utils/Variables';

import EditMembershipCard from './editMembershipCard';

const MembershipCard=()=>{
	
	const context = useContext(PsContext);
	
	const [loader, setLoader] = useState(false);
	const [addModal, showAddModal] = useState(false);
	const [editModal, showEditModal] = useState(false);
	
	const [filter, setFilter] = useState({
		page: 1,
		page_size:100,
		pastorate: '',
	});
	
	const [totalRows, setTotalRows] = useState(0);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	
	const [pastorate, setPastorate] = useState([]);
	const [selectedPastorate, setSelectedPastorate] = useState(null);
	
	const [searchText, setSearchText] = useState('');
	
	const [data, setData] = useState([]);
	const [viewData, setViewData] = useState([]);
	
	const [editData, setEditData] = useState([]);
	
	async function loadData(){
		setData([]);
		setViewData([]);
		setLoader(true);
		var q = objectToQueryString(filter);
		
		await api.get(`v1/membership/list?api=${context.state.api}&${q}`).then(res=>{
			if(res['data'].errorcode=='200'){
				setData(res['data'].data);
				setViewData(res['data'].data);
				setTotalRows(res['data'].total);
			}
			setLoader(false);
		});
	};
	
	const loadPastorate=()=>{
		setLoader(true);
		api.get(`v1/pastorate/list?status=1&api=${context.state.api}`).then(res=>{
			if(res['data'].errorcode=='200'){
				setPastorate(res['data'].data);
			}
			setLoader(false);
		});
	};
	
	useEffect(()=>{
		
		loadData();
		loadPastorate();
	},[]);
	
	useEffect(()=>{
		
		loadData();
	
	},[filter]);
	

	const currentStatus=(item)=>{
		if(item.active_status == '0'){
			return <span className="text-danger">In Active</span>;
		}
		else{
			return <span className="text-success">Active</span>;
		}
	};
	
	const handleDeleteActionClick=(item)=>{
		if(!window.confirm('Do you want to Delete?')){
			return;
		}
		
		setLoader(true);
		api.get(`v1/church/remove?id=${item.id}&api=${context.state.api}`).then(res=>{
			if(res['data'].errorcode=='200'){
				var m = data.filter(bt => bt.id != item.id);
				setData(m);
				setViewData(m);			
						
				toast.success(res['data'].content || 'Success');
			}
			else{
				toast.error(res['data'].content || 'Error');
			}
			setLoader(false);
		});
	};
	
	const refresh=()=>{
		toast.promise(loadData(),
		   {
			 loading: 'Loading...',
			 success: '',
			 error: 'No data found',
		   }
		 );
	};
	
	const handleSearch=(e)=>{
		/* var v = upperCase(e.target.value);
		var m = data.filter(function(obj){
			return(upperCase(obj.member_name).indexOf(v)>-1 || upperCase(obj.aadhar_no).indexOf(v)>-1);
		});
		setViewData(m); */
		setSearchText(e.target.value);
	};
	
	const pastorateChange=(e)=>{
		
		setFilter({...filter, pastorate: e.target.value, page: 1});
		
	};
	
	const handleSearchClick=(e)=>{
		
		setFilter({...filter, member_name: searchText, page: 1});
	};
	
	const onPageChange=(v)=>{
		//setPage(v.selected+1);
		let f = filter;
		//let p = filter.page;
		//f.page = v.selected+1;
		setFilter({...filter, page: v.selected+1});
		//loadData();
	}
	
	
	return(
	<>
		<Row>
		
			<Col md={12} >
				<div className="panel panel-form" >
					<div className="panel-header">
						<Row>
							<Col md={8} >
								<div className="d-flex justify-content-start align-items-center" >Membership Card List</div>
							</Col>
							<Col md={4} >
								<div className="text-right" >
									<ReactHTMLTableToExcel
									id="test-table-xls-button"
									className="btn btn-primary btn-sm"
									table="export_members"
									filename="Members"
									sheet="Sheet 1"
									buttonText={<span><i className="icofont-file-excel pe-1 me-1 border-right"></i> Download as XLS</span>} />
									&nbsp;
									<Button
										type="button"
										size="sm"
										onClick={()=> loadData() }
									>
										<i className="icofont-refresh pe-1 me-1 border-right"></i> Refresh
									</Button>
									&nbsp;
									<Link
										to="/admin/membership-card/new"
										className="btn btn-primary btn-sm"
									>
										<i className="icofont-plus pe-1 me-1 border-right"></i> Add New Member
									</Link>
								</div>
							</Col>
							
						</Row>
					</div>
					<div className="panel-body" >
						
						<Row>
							<Col md={4} >
								<div className="input-group input-group-sm">
								<Form.Control
									type="text"
									size="sm"
									placeholder="Search by Member Name, Aadhar No.."
									onChange={(e)=> handleSearch(e) }
									autoFocus={true}
									value={searchText}
								/>
									<div className="input-group-text px-0 py-0">
										<Button size="sm" variant="light" onClick={(e)=> handleSearchClick(e) } >
											Search <i className="icofont-eye ps-2"></i>
										</Button>
									</div>
								</div>
							</Col>
							<Col md={2} >
								<Form.Control
									as="select"
									size="sm"
									name="pastorate_name"
									required
									onChange={(e)=> pastorateChange(e) }
									autoFocus={true}
								>
									<option value=""> Select Pastorate</option>
									{pastorate && pastorate.length>0 && (pastorate.map((item,i)=> <option value={item.id} key={i}  > {item.name} </option>))}
								</Form.Control>
							</Col>	
							<Col md={6}>
								<div className="text-right" style={{float:'right', height: '30px'}} >
									  <ReactPaginate
										nextLabel={<i className="icofont-double-right"></i>}
										containerClassName="pagination"
										pageClassName="page-item"
										pageLinkClassName="page-link"
										pageRangeDisplayed={2}
										pageCount={Math.ceil(totalRows/filter.page_size)}
										previousLabel={<i className="icofont-double-left"></i>}
										renderOnZeroPageCount={null}
										onPageChange={onPageChange}
										forcePage={filter.page-1}
									  />
								</div>
							</Col>
						</Row>
						
						
					<div className="table-responsive mt-5 tableFixHead" >
						<table className="table table-striped-lite table-hover ">
							<thead>
								<tr>
									<th width="60" >S.NO</th>									
									<th width="60" >Photo</th>									
									<th>Aadhar No</th>
									<th>Member Name</th>
									<th>DOB</th>
									<th>Pastorate</th>
									<th>Church</th>
									<th>Sabai</th>
									<th>Mobile</th>
									<th>STATUS</th>
									<th width="100" >ACTION</th>
								</tr>
							</thead>
							<tbody>
							{viewData && viewData.length>0 && (viewData.map((item,i)=> {
								return <tr key={i} >
									<td>{(filter.page_size*(filter.page-1))+(i+1)}</td>
									<td>
										<Avatar src={<Image src={`${Api.Url}img/m/${item.aadhar_no}`} style={{ width: 32 }} />} />
									</td>
									<td>{item.aadhar_no}</td>
									<td>{item.member_name}</td>
									<td>{momentDate(item.date_of_birth, 'DD.MM.YYYY')}</td>
									<td>{item.pastorate_name}</td>
									<td>{item.church_name}</td>
									<td>{item.sabai_name}</td>
									<td>{item.mobile}</td>
									<td>{currentStatus(item)}</td>
									<td align="center" >
									<Button 
											type="button" 
											size="xs" 
											variant="default" 
											onClick={()=>{
												setEditData(item);
												showEditModal(true);
											}} 
										> 
												<i className="icofont-edit"></i>
										</Button>
										&nbsp;
										<a target="_blank" href={`${Api.Url}pdf/m/${item.aadhar_no}?api=${context.state.api}`}
											className="btn btn-xs btn-default" 
										>
											<i className="icofont-file-pdf"></i>
										</a>
										&nbsp;
										<a target="_blank" href={`${Api.Url}pdf/profile/${item.aadhar_no}?api=${context.state.api}`}
											className="btn btn-xs btn-default" 
										>
											<i className="icofont-ui-file"></i>
										</a>
									</td>
								</tr>;
							})
							)}
							</tbody>
						</table>						
					</div>		
					
						{loader && (<center>
							<Spinner animation="border" role="status" />
						 </center>)}
						 
					</div>
					
					<div className="panel-footer">
						<Row>
							<Col md={12} >
								<div className="text-right py-5" >
									<b>{totalRows} Members in list</b>
								</div>
							</Col>
						</Row>	
					</div>
					
				</div>
			</Col>
			
			
			<Modal
			size="lg"
			className="cb__modal__search"
			backdrop="static"
			show={editModal}
			onHide={()=> {
				setEditData([]);
				showEditModal(false);
			}}
		>
			<Modal.Header closeButton >
				Update Member
			</Modal.Header>
			<Modal.Body>
				<EditMembershipCard 
					data={editData}
					afterFinish={()=> loadData() }
				/>
			</Modal.Body>
		</Modal>
			
			<div style={{display: 'none' }} >
				<table id="export_members" >
					<thead>
						<tr>
							<th>S.No</th>
							<th>Pastorate</th>
							<th>Church Name</th>
							<th>Sabai Name</th>
							<th>Aadhar No</th>
							<th>Member Name</th>
							<th>Tamil Name</th>
							<th>Father Name</th>
							<th>Date of Birth</th>
							<th>Place of Birth</th>
							<th>Date of Baptism</th>
							<th>Place of Baptism</th>
							<th>Date of Confirmation</th>
							<th>Place of Confirmation</th>
							<th>Date of First Holy Communion</th>
						</tr>
					</thead>
					<tbody>
					{viewData && viewData.length>0 && (viewData.map((item,i)=>{
						return <tr key={i} >
							<td>{i+1}</td>
							<td>{item.pastorate_name}</td>
							<td>{item.church_name}</td>
							<td>{item.sabai_name}</td>
							<td>{item.aadhar_no}</td>
							<td>{item.member_name}</td>
							<td>{item.tamil_name}</td>
							<td>{item.father_name}</td>
							<td>{momentDate(item.date_of_birth, 'DD.MM.YYYY')}</td>
							<td>{item.place_of_birth}</td>
							<td>{momentDate(item.date_of_baptism, 'DD.MM.YYYY')}</td>
							<td>{item.place_of_baptism}</td>
							<td>{momentDate(item.date_of_communion, 'DD.MM.YYYY')}</td>
							<td>{item.place_of_communion}</td>
							<td>{momentDate(item.first_holy_communion, 'DD.MM.YYYY')}</td>
						</tr>;
					}))
					}
					</tbody>
				</table>	
			</div>
			
		</Row>
			
	</>
	);
};

export default MembershipCard;