import React from 'react';

import Routes from './routes';
import PsContextProvider from './context/PsContextProvider';

export default () => (

  <PsContextProvider>
    <Routes />
  </PsContextProvider>

);
