import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import {Row, Col, Card, Form, Button} from 'react-bootstrap';
import {Spin, message} from 'antd';

import PsContext from '../../../context/PsContext';

class LoginForm extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			role: null,
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true});
			axios.post(Api.Url+'v1/login', $("#cb__frmLogin").serialize()).then(res=>{
				if(res['data'].status=='1'){
					
					this.context.updateCompany(res['data'].hospital);
					this.context.updateLoggedAcyear(res['data'].acyears);
					this.context.updatePharmacy(res['data'].pharmacy);
					
					this.context.updateUserLogin(res['data'].data, res['data'].api);
					
					message.success(res['data'].message || 'Success');
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false});
		}
	}
		
	render(){
		
		
		return(
			<div className="" >
			
						<form 
							action=""
							method="post"
							encType="multipart/form-data" 
							id="cb__frmLogin"
							onSubmit={this.handleFormSubmit} 
						>
						
						{/*<Row>
							<Col md={12} >
								<img src={LOGO} 
									style={{
										width: '100%'
									}}
								/>
							</Col>
						</Row>*/}
							
						<Spin spinning={this.state.showLoader} >
							
							<Row className="mt-15" >
								<Col md={12} >
									<label>Username</label>
									<Form.Control
										type="text"
										name="cb__txtUsername"
										id="cb__txtUsername"
										size="lg"
										placeholder="Username"
										autoFocus={true}
										required
									/>
								</Col>
							</Row>
							
							<Row className="mt-20" >
								<Col md={12} >
									<label>Password</label>
									<Form.Control
										type="password"
										name="cb__txtPassword"
										id="cb__txtPassword"
										size="lg"
										placeholder="Password"
										required
									/>
								</Col>
							</Row>
							
							
							<Row className="mt-35" >
								<Col md={12} >
									<div className="text-right" >
										<Button
											size="md"
											variant="primary"
											type="submit"
											className="font-weight-600"
										>
											<i className="icofont-check pe-2 me-2 border-right"></i> Continue
										</Button>										
									</div>
								</Col>
							</Row>
							
							</Spin>
							
						</form>
				
			</div>
		);
	}
	
}
export default LoginForm;
