import Dashboard from './Dashboard';

import Pastorate from './pastorate';
import Churches from './churches';
import Sabai from './sabai';

import MembershipCard from './membershipcard';
import AddMembershipCard from './membershipcard/addMembershipCard';


const pageList = [
  { name: 'Dashboard', path: '/admin', component: Dashboard, allowed: ['admin']},
  
  { name: 'Pastorate', path: '/admin/settings/pastorate', component: Pastorate, allowed: ['admin']},
  { name: 'Churches', path: '/admin/settings/churches', component: Churches, allowed: ['admin']},
  { name: 'Sabai', path: '/admin/settings/sabai', component: Sabai, allowed: ['admin']},
  
  { name: 'Membership Card', path: '/admin/membership-card/list', component: MembershipCard, allowed: ['admin']},
  { name: 'Add Membership Card', path: '/admin/membership-card/new', component: AddMembershipCard, allowed: ['admin']},
  
];

export default pageList;
