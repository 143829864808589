import React from 'react';
import { Switch, Route, HashRouter } from 'react-router-dom';

import AdminLayout from '../modules/Admin/Layout';

import Login from '../views/pages/Login';

export default () => (

  <HashRouter>
    <Switch>
      
	  <Route path="/admin" component={AdminLayout} />
	
      <Route path="/" component={Login} />

    </Switch>
  </HashRouter>

);
