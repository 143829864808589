/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

import {getls, lowerCase} from '../../utils';
import nav from './_nav';
import routes from './routes';

import PsContext from '../../context/PsContext';
import Header from './components/header';

class Layout extends Component {
	
	static contextType = PsContext;
	
  componentDidMount() {

  }

  render() {
	  
    return (

      <div className="page-sidebar-fixed page-full-width fade in" id="page-container" >
		
		<Toaster 
			toastOptions={{
				style: {
				  borderRadius: '10px',
				  background: '#333',
				  color: '#fff',
				},
			}}
		/>
		
		<Header  
			routes={routes} 
			nav={nav}
			role={lowerCase(this.context.state.loggedUser.role)} 
			{...this.props} 
		/>
		
        <div id="content" className="content">
          

            <Switch>
              {routes.map((page) => (
				  page.allowed && lowerCase(page.allowed).indexOf(lowerCase(this.context.state.loggedUser.role)) > -1 ? <Route exact path={page.path} component={page.component} key={page} /> : null
              ))}
            </Switch>


        </div>
		
		<iframe name="print_frame" id="print_frame" width="0" height="0" frameBorder="0" src="about:blank"></iframe>
		
      </div>

    );
  }
}
export default Layout;
