import React, { useState, useContext, useEffect } from 'react';
import $ from 'jquery';
import { Row, Col, Button, Form, Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';

import PsContext from '../../../context';
import api from '../../../utils/api';

const EditChurch=(props)=>{
	
	const context = useContext(PsContext);
	
	const [loader, setLoader] = useState(false);
	const [validated, setValidated] = useState(false);
	
	const [pastorate, setPastorate] = useState([]);
	
	const loadData=()=>{
		setLoader(true);
		api.get(`v1/pastorate/list?status=1&api=${context.state.api}`).then(res=>{
			if(res['data'].errorcode=='200'){
				setPastorate(res['data'].data);
			}
			setLoader(false);
		});
	};
	
	useEffect(()=>{
		
		loadData();
	},[]);
	
	const handleSubmit=(e)=>{
		e.preventDefault();
		const form = e.currentTarget;
		if (form.checkValidity() === false) {
		  e.stopPropagation();
		  setValidated(true);
		  return;
		}
		
		setLoader(true);
		api.post('v1/church/update', $("#frm_edit_church").serialize()).then(res=>{
			if(res['data'].errorcode=='200'){
				toast.success(res['data'].content || 'Success');
				setLoader(false);
				
				if(props.afterFinish)
					props.afterFinish();
			}
			else{
				toast.error(res['data'].content || 'Error');
				setLoader(false);
			}
		});
	}
	
	const field=(f)=>{
		
		return props.data[f] || '';
	};
	
	return(
	<>
		<Form 
			noValidate 
			validated={validated} 
			id="frm_edit_church"
			onSubmit={handleSubmit}
		>
			<div className="cb__modal__body" >
				<input type="hidden" name="api" value={context.state.api} />
				<input type="hidden" name="id" value={field('id')} />
				<Row>
					<Col md={12} >
						<label>Select the Pastrorate <span className="text-danger">*</span> </label>
						<Form.Control
							as="select"
							size="md"
							name="pastorate_name"
							required
							autoFocus={true}
						>
							<option value=""> Select </option>
							{pastorate && pastorate.length>0 && (pastorate.map((item,i)=> 
								<option value={item.id} key={i} selected={item.id==field('pastorate_id') ? 'selected' : ''} > {item.name} </option>
							))}
						</Form.Control>
					</Col>	
				</Row>
				
				<Row className='mt-1' >
					<Col md={10} >
						<label>Name of the Church <span className="text-danger">*</span> </label>
						<Form.Control
							type="text"
							size="md"
							name="church_name"			
							defaultValue={field('name')}
							required
						/>
					</Col>	
					<Col md={2} >
						<label>Is Main?<span className="text-danger">*</span> </label>
						<Form.Control
							as="select"
							size="md"
							name="is_main"							
							required
						>
							<option value="0" selected={field('is_main')=='0' ? 'selected' : ''} > NO </option>
							<option value="1" selected={field('is_main')=='1' ? 'selected' : ''} > YES </option>
						</Form.Control>
					</Col>	
				</Row>
				
				
				<Row className='mt-1' >
					<Col md={6} >
						<label>Mobile <span className="text-danger"></span> </label>
						<Form.Control
							type="text"
							size="md"
							name="mobile"	
							defaultValue={field('mobile')}							
						/>
					</Col>	
					<Col md={6} >
						<label>Pastor Name <span className="text-danger"></span> </label>
						<Form.Control
							type="text"
							size="md"
							name="pastor_name"		
							defaultValue={field('pastor_name')}							
						/>
					</Col>	
				</Row>
				
				<Row className="mt-1" >
					<Col md={12} >
						<label>Church Address <span className="text-danger"></span> </label>
						<Form.Control
							as="textarea"
							rows="4"
							name="address"
							defaultValue={field('address')}	
						/>
					</Col>	
				</Row>
				
				<Row className="mt-3" >
					<Col md={3} >
						<label>Status <span className="text-danger">*</span> </label>
						<Form.Control
							as="select"
							name="status"
							required
						>
							<option value="1" selected={field('active_status')=='1' ? 'selected' : ''} > Active </option>
							<option value="0" selected={field('active_status')=='0' ? 'selected' : ''}  > In-Active </option>
						</Form.Control>
					</Col>	
				</Row>
				
			</div>
			<div className="cb__modal__footer" >
				<Row>
					<Col md={12} >
						<div className="text-right" >
							<Button
								type="submit"
								size="sm"
								variant="primary"
								disabled={loader}
							>
							{loader ? <>
								<Spinner animation="border" size="sm" /> <span className="ps-2 border-left">WAIT..</span>
							</> : <>
								<i className="icofont-check pe-2 border-right" ></i> UPDATE
							</>}
							</Button>
						</div>
					</Col>
				</Row>
			</div>
		</Form>
	</>
	);
};

export default EditChurch;