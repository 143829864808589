import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Row, Col, Button, Form, Spinner, Alert } from 'react-bootstrap';
import { Spin } from 'antd';
import toast from 'react-hot-toast';

import PsContext from '../../../context';
import { momentDate } from '../../../utils';
import api from '../../../utils/api';
import { Api } from '../../../utils/Variables';

const styles = {
	imgStyle: {
		width: '130px',
		height: '140px',
		border: '1px solid rgb(205 205 205)',
		borderRadius: '3px',
	}
};

const VerifyCard=(props)=>{
	
	const context = useContext(PsContext);
	
	const [loader, setLoader] = useState(false);
	const [validated, setValidated] = useState(false);
	const [data, setData] = useState([]);
	
	
	const handleSubmit=(e)=>{
		e.preventDefault();
		const form = e.currentTarget;
		if (form.checkValidity() === false) {
		  e.stopPropagation();
		  setValidated(true);
		  return;
		}
		setLoader(true);	
		api.post('v1/membership/verify', $("#frm_verify_card_1").serialize()).then(res=>{
			if(res['data'].errorcode=='200'){
				setData(res['data'].data);
			}
			else{
				toast.error(res['data'].content || 'Error');
			}
			setLoader(false);
		});
	}
		
	return(
	<>
									
		<Form 
			noValidate 
			validated={validated} 
			id="frm_verify_card_1"
			onSubmit={handleSubmit}
		>
		
			<div className="panel-body cb__modal__body px-20" style={{height: 'auto' }}  >
			<Spin spinning={loader} >
			<input type="hidden" name="api" value={context.state.api} />
				
				{data && data.length<1 && (<Row className="my-30" >
					<Col md={10} >
						<label>Scan or Enter Member Secret Code</label>
						<Form.Control
							type="text"
							name="secret_code"
							size="sm"
							required
						/>
					</Col>
				</Row>)}
				
				{data && data.id && (<div className="my-15" >
					<Row>
						<label className="col-md-3 control-label">Member Name</label>
						<Col md={9} >
							<Form.Control size="sm" value={data.member_name} />
						</Col>
					</Row>
					<Row className="" >
						<label className="col-md-3 control-label"></label>
						<Col md={9} >
							<Form.Control size="sm" value={data.tamil_name} />
						</Col>
					</Row>
					<Row className="" >
						<label className="col-md-3 control-label"></label>
						<Col md={5} >
							<img src={`${Api.Url}img/m/${data.aadhar_no}`} style={styles.imgStyle} />
						</Col>
						<Col md={4} className="d-flex align-items-center" >
							<a target="_blank" href={`${Api.Url}pdf/m/${data.aadhar_no}?api=${context.state.api}`} className="btn btn-default ">
								View Card <i className="icofont-external-link ps-2 ms-2 border-left border-secondary"></i>
							</a>
						</Col>
					</Row>
					<Row className="mt-2" >
						<label className="col-md-3 control-label">Aadhar No</label>
						<Col md={9} >
							<Form.Control size="sm" value={data.aadhar_no} />
						</Col>
					</Row>
					<Row className="mt-2" >
						<label className="col-md-3 control-label">Father Name</label>
						<Col md={9} >
							<Form.Control size="sm" value={data.father_name} />
						</Col>
					</Row>
					<Row className="mt-2" >
						<label className="col-md-3 control-label">Pastorate</label>
						<Col md={9} >
							<Form.Control size="sm" value={data.pastorate_name} />
						</Col>
					</Row>
					<Row className="mt-2" >
						<label className="col-md-3 control-label">Church Name</label>
						<Col md={9} >
							<Form.Control size="sm" value={data.church_name} />
						</Col>
					</Row>
					<Row className="mt-2" >
						<label className="col-md-3 control-label">Sabai </label>
						<Col md={9} >
							<Form.Control size="sm" value={data.sabai_name} />
						</Col>
					</Row>
				</div>)}
				
				</Spin>
			</div>
			<div className="panel-footer cb__modal__footer">
				<Row>
					<Col md={8} >
						
					</Col>
					<Col md={4} >
						<div className="text-right" >
							{data && data.length<1 && (<Button
									type="submit"
									size="sm"
									variant="default"
									disabled={loader}
								>
								{loader ? <>
									<Spinner animation="border" size="sm" /> <span className="ps-2 border-left">WAIT..</span>
								</> : <>
									<i className="icofont-search pe-2 me-2 border-right border-secondary" ></i> VERIFY
								</>}
							</Button>)}
							{data && data.id && (<Button
									type="reset"
									size="sm"
									variant="default"
									disabled={loader}
									onClick={ e => setData([]) }
								>
								<i className="icofont-close pe-2 me-2 border-right border-secondary" ></i> RESET
							</Button>)}
						</div>
					</Col>
				</Row>
			</div>
			
		</Form>
				
				
				
			
	</>
	);
};

export default VerifyCard;