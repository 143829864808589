export default {
  top: [
    {
      name: 'Dashboard', url: '/pharmacy', icon: 'Home', ionicon: 'icofont-ui-home', exact: true, allowed: ['admin'],
    },
	{
      name: 'Membership Card', url: '/admin/membership-card', icon: 'Home', ionicon: 'icofont-basket', exact: true, allowed: ['admin'],
	  children: [
		{ name: 'View All Cards', url: '/admin/membership-card/list', icon: 'Home', ionicon: 'icofont-cart', exact: true, allowed: ['admin'] },
		{ name: 'Add New Card', url: '/admin/membership-card/new', icon: 'Home', ionicon: 'icofont-list', exact: true, allowed: ['admin'] },
	  ]
    },
	{
      name: 'Settings', url: '/admin/settings', icon: 'Home', ionicon: 'icofont-basket', exact: true, allowed: ['admin'],
	  children: [
		{ name: 'Sabai', url: '/admin/settings/sabai', icon: 'Home', ionicon: 'icofont-cart', exact: true, allowed: ['admin'] },
		{ name: 'Churches', url: '/admin/settings/churches', icon: 'Home', ionicon: 'icofont-cart', exact: true, allowed: ['admin'] },
		{ name: 'Pastorate', url: '/admin/settings/pastorate', icon: 'Home', ionicon: 'icofont-list', exact: true, allowed: ['admin'] },
	  ]
    },
  ],
  bottom: [
   
  ],
};
