const COOKIE_KEY = 'tata_312';

/* const Api = {
  Url: 'http://localhost/csitvl-api/',
  ImageUrl: 'http://localhost/csitvl-api/public/uploads', // Dont put slash in last
  SiteUrl: 'http://localhost/tdta/',
  version: 'v1/',
}; */
 
const Api = {
  Url: 'https://csitvl.peacesoft.in/api/',
  ImageUrl: 'https://csitvl.peacesoft.in/api/public/uploads', // Dont put slash in last
  SiteUrl: 'https://csitvl.peacesoft.in/',
  version: 'v1/',
};

const Var = {
  Title: 'Billing',
  Site: '',
  Phone: '9487900120',
  Email: '',
  Address: '',
  City: 'Tirunelveli',
  Pincode: ' 627002',
  RegularCourseFeeForScSt: 0,
  RegularCourseFeeForOthers: 50,
  SelfCourseFeeForScSt: 50,
  SelfCourseFeeForOthers: 50,
  EnableCashPayment: true,
  EnableOnlinePayment: true,
};

const copyRight = {
	version: '1.0',
	year: '2021',
	mobile: '+91 94879 00120',
	email: 'help@peacesoft.in',
};

export {
  COOKIE_KEY,
  Api,
  Var,
  copyRight,
};
