import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Button, Modal, Spinner, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';

import PsContext from '../../../context';
import api from '../../../utils/api';
import { upperCase } from '../../../utils';

import AddChurch from './addChurch';
import EditChurch from './editChurch';

const Churches=()=>{
	
	const context = useContext(PsContext);
	
	const [loader, setLoader] = useState(false);
	const [addModal, showAddModal] = useState(false);
	const [editModal, showEditModal] = useState(false);
	
	const [data, setData] = useState([]);
	const [viewData, setViewData] = useState([]);
	
	const [editData, setEditData] = useState([]);
	
	async function loadData(){
		setData([]);
		setViewData([]);
		setLoader(true);
		await api.get(`v1/church/list?api=${context.state.api}`).then(res=>{
			if(res['data'].errorcode=='200'){
				setData(res['data'].data);
				setViewData(res['data'].data);
			}
			setLoader(false);
		});
	}
	
	
	
	useEffect(()=>{
		
		loadData();
	},[]);
	
	const currentStatus=(item)=>{
		if(item.active_status == '0'){
			return <span className="text-danger">In Active</span>;
		}
		else{
			return <span className="text-success">Active</span>;
		}
	};
	
	const handleDeleteActionClick=(item)=>{
		if(!window.confirm('Do you want to Delete?')){
			return;
		}
		
		setLoader(true);
		api.get(`v1/church/remove?id=${item.id}&api=${context.state.api}`).then(res=>{
			if(res['data'].errorcode=='200'){
				var m = data.filter(bt => bt.id != item.id);
				setData(m);
				setViewData(m);			
						
				toast.success(res['data'].content || 'Success');
			}
			else{
				toast.error(res['data'].content || 'Error');
			}
			setLoader(false);
		});
	};
	
	const refresh=()=>{
		toast.promise(loadData(),
		   {
			 loading: 'Loading...',
			 success: '',
			 error: 'No data found',
		   }
		 );
	};
	
	const handleSearch=(e)=>{
		var v = upperCase(e.target.value);
		var m = data.filter(function(obj){
			return(upperCase(obj.name).indexOf(v)>-1 || upperCase(obj.pastorate_name).indexOf(v)>-1);
		});
		setViewData(m);
	};
	
	return(
	<>
		<Row>
		
			<Col md={10} >
				<div className="panel panel-form" >
					<div className="panel-header">
						<Row>
							<Col md={8} >
								<div className="d-flex justify-content-start align-items-center" >Churches</div>
							</Col>
							<Col md={4} >
								<div className="text-right" >
									<Button
										type="button"
										size="sm"
										onClick={()=> loadData() }
									>
										<i className="icofont-refresh pe-1 me-1 border-right"></i> Refresh
									</Button>
									&nbsp;
									<Button
										type="button"
										size="sm"
										onClick={()=> showAddModal(true) }
									>
										<i className="icofont-plus pe-1 me-1 border-right"></i> Add Churche
									</Button>
								</div>
							</Col>
						</Row>
					</div>
					<div className="panel-body" >
						
						<Row>
							<Col md={4} >
								<Form.Control
									type="text"
									size="sm"
									placeholder="Search by Church Name, Pastorate Name.."
									onChange={(e)=> handleSearch(e) }
								/>
							</Col>
						</Row>
						
						
					<div className="table-responsive mt-10 tableFixHead" >
						<table className="table table-striped-lite table-hover ">
							<thead>
								<tr>
									<th width="60" >S.NO</th>									
									<th>Name of the Pastorate</th>
									<th>Name of the Church</th>
									<th>Mobile</th>
									<th>Is Main</th>
									<th>STATUS</th>
									<th width="120" >ACTION</th>
								</tr>
							</thead>
							<tbody>
							{viewData && viewData.length>0 && (viewData.map((item,i)=> {
								return <tr key={i} >
									<td>{i+1}</td>
									<td>{item.pastorate_name}</td>
									<td>{item.name}</td>
									<td>{item.mobile}</td>
									<td align="center" >{item.is_main=='1' ? 'Yes' : ''}</td>
									<td>{currentStatus(item)}</td>
									<td align="center" >
										<Button 
											type="button" 
											size="xs" 
											variant="default" 
											onClick={()=>{
												setEditData(item);
												showEditModal(true);
											}} 
										> 
												<i className="icofont-edit"></i>
										</Button>
										&nbsp;
										<Button 
											type="button" 
											size="xs" 
											variant="default" 
											onClick={()=> handleDeleteActionClick(item)} 
										> 
												<i className="icofont-trash"></i>
										</Button>
									</td>
								</tr>;
							})
							)}
							</tbody>
						</table>						
					</div>		
					
						{loader && (<center>
							<Spinner animation="border" role="status" />
						 </center>)}
						 
					</div>
				</div>
			</Col>
			
		</Row>
		
		<Modal
			size="md"
			className="cb__modal__search"
			backdrop="static"
			show={addModal}
			onHide={()=> showAddModal(false) }
		>
			<Modal.Header closeButton >
				Add Church
			</Modal.Header>
			<Modal.Body>
				<AddChurch 
					afterFinish={()=> loadData() }
				/>
			</Modal.Body>
		</Modal>
		
		<Modal
			size="md"
			className="cb__modal__search"
			backdrop="static"
			show={editModal}
			onHide={()=> {
				setEditData([]);
				showEditModal(false);
			}}
		>
			<Modal.Header closeButton >
				Update Church
			</Modal.Header>
			<Modal.Body>
				<EditChurch 
					data={editData}
					afterFinish={()=> loadData() }
				/>
			</Modal.Body>
		</Modal>
	
	</>
	);
};

export default Churches;