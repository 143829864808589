import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Row, Col, Button, Form, Spinner, Alert, ButtonGroup, ToggleButton } from 'react-bootstrap';
import toast from 'react-hot-toast';

import PsContext from '../../../context';
import { nameWithDotAndHypen, integerAadhar, momentDate } from '../../../utils';
import api from '../../../utils/api';

const styles = {
	imgStyle: {
		width: '150px',
		height: '160px',
		border: '1px solid rgb(205 205 205)',
		borderRadius: '3px',
	}
};

const AddMembershipCard=(props)=>{
	
	const context = useContext(PsContext);
	
	const getToday=()=>{
		return momentDate((new Date()),'YYYY-MM-DD');
	};
	
	const [loader, setLoader] = useState(false);
	const [validated, setValidated] = useState(false);
	
	const [pastorate, setPastorate] = useState([]);
	const [churches, setChurches] = useState([]);
	
	const [church, setChurch] = useState([]);
	const [aadhar, setAadhar] = useState('');
	const [newFile, setNewFile] = useState(null);
	
	const [sabais, setSabais] = useState([]);
	const [sabai, setSabai] = useState([]);
	
	const [baptimsAt, setBaptismAt] = useState('pastorate');
	
	const loadData=()=>{
		setLoader(true);
		api.get(`v1/pastorate/list?status=1&api=${context.state.api}`).then(res=>{
			if(res['data'].errorcode=='200'){
				setPastorate(res['data'].data);
			}
			setLoader(false);
		});
	};
	
	const loadChurches=()=>{
		setLoader(true);
		api.get(`v1/church/list?status=1&api=${context.state.api}`).then(res=>{
			if(res['data'].errorcode=='200'){
				setChurches(res['data'].data);
			}
			setLoader(false);
		});
	};
	
	const loadSabai=()=>{
		setLoader(true);
		api.get(`v1/sabai/list?status=1&api=${context.state.api}`).then(res=>{
			if(res['data'].errorcode=='200'){
				setSabais(res['data'].data);
			}
			setLoader(false);
		});
	};
	
	useEffect(()=>{
		
		loadData();
		loadChurches();
		loadSabai();
	},[]);
	
	const handleSubmit=(e)=>{
		e.preventDefault();
		const form = e.currentTarget;
		if (form.checkValidity() === false) {
		  e.stopPropagation();
		  setValidated(true);
		  return;
		}
		
		if(aadhar.length!=12){
			toast.error('Enter valid 12 digit Aadhar Number');
			return;
		}
		
		if(!window.confirm('Do you want to save?')){
			return;
		}
		const formdata = new FormData(form);
		
		setLoader(true);
		//api.post('v1/membership/create', $("#frm__add_member_n").serialize()).then(res=>{
		api.post('v1/membership/create', formdata).then(res=>{
			if(res['data'].errorcode=='200'){
				document.getElementById("frm__add_member_n").reset();
				toast.success(res['data'].content || 'Success');
				setLoader(false);
				removeFile();
				if(props.afterFinish)
					props.afterFinish();
			}
			else{
				toast.error(res['data'].content || 'Error');
				setLoader(false);
			}
		});
	}
	
	const pastorateChange=(e)=>{
		var v = e.target.value;
		var m = churches.filter(item => item.pastorate_id==v);
		if(m.length>0){
			setChurch(m);
		}
	};
	
	const churchChange=(e)=>{
		var v = e.target.value;
		var m = sabais.filter(item => item.church_id==v);
		if(m.length>0){
			setSabai(m);
		}
	};
	
	const handleSelectFiles=(e)=>{
		
		let files_1 = e.target.files;			
		 const files = files_1[0];
		  if (files) {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(files);
			fileReader.addEventListener("load", function () {				
				setNewFile(this.result);
			});   
		  }
	}
	
	const removeFile=()=>{
		document.getElementById("hb_file-upload").value= null;
		setNewFile(null);
	}
	
	
	return(
	<>
		<Row>
			<Col md={10} >
			
				<div className="panel panel-form" >
				
					<div className="panel-header" >
						<Row>
							<Col md={6} >
								Add New Membership Card
							</Col>
							<Col md={6} >
								<div className="text-right" >
									<Link
										to="/admin/membership-card/list"
										className="btn btn-primary btn-sm"
									>
										<i className="icofont-arrow-left pe-1 me-1 border-right"></i> Back to List
									</Link>
								</div>
							</Col>
						</Row>
					</div>
					
					<Form 
						noValidate 
						validated={validated} 
						id="frm__add_member_n"
						onSubmit={handleSubmit}
					>
					<div className="panel-body" >
						
						
						<input type="hidden" name="api" value={context.state.api} />
						<input type="hidden" name="confirmation_at" value={baptimsAt} />
							
							<Row>
								<Col md={10} >
							
							<Row className="mt-2" >
								<label className="col-md-2 col-form-label" >Pastrorate <span className="text-danger">*</span> </label>
								<Col md={4} >
									<Form.Control
										as="select"
										size="md"
										name="pastorate_name"
										required
										onChange={(e)=> pastorateChange(e) }
										autoFocus={true}
									>
										<option value=""> Select </option>
										{pastorate && pastorate.length>0 && (pastorate.map((item,i)=> <option value={item.id} key={i}> {item.name} </option>))}
									</Form.Control>
								</Col>	
								<label className="col-md-2 col-form-label" >Church <span className="text-danger">*</span> </label>
								<Col md={4} >
									<Form.Control
										as="select"
										size="md"
										name="church_name"
										onChange={ e => churchChange(e) }
										required
									>
										<option value=""> Select </option>
										{church && church.length>0 && (church.map((item,i)=> <option value={item.id} key={i}> {item.name} </option>))}
									</Form.Control>
								</Col>
								
							</Row>
							
							<Row className="mt-1" >
								<label className="col-md-2 col-form-label" >Sabai <span className="text-danger">*</span> </label>
								<Col md={4} >
									<Form.Control
										as="select"
										size="md"
										name="sabai_name"
										required
									>
										<option value=""> Select </option>
										{sabai && sabai.length>0 && (sabai.map((item,i)=> <option value={item.id} key={i}> {item.name} </option>))}
									</Form.Control>
								</Col>	
								
							</Row>
							
							
							<Row className="mt-1" >
								<label className="col-md-2 col-form-label" >Name (Eng) <span className="text-danger">*</span> </label>
								<Col md={4} >
									<Form.Control
										type="text" 
										size="md"
										name="name"
										placeholder="Name of the Member in English"
										className="text-uppercase"
										onKeyPress={nameWithDotAndHypen} 
										required
									/>
								</Col>
								<label className="col-md-2 col-form-label" >Name (Tamil) <span className="text-danger">*</span> </label>
								<Col md={4} >
									<Form.Control
										type="text" 
										size="md"
										name="tamil_name"
										placeholder="உறுப்பினரின் பெயர் (தமிழில்)"
										className="text-uppercase"
										onKeyPress={nameWithDotAndHypen} 
										required
									/>
									<a href="https://www.google.com/intl/ta/inputtools/try/" target="_blank" style={{ position: 'absolute'}}>Online Tamil Typing Tool</a>
								</Col>							
							</Row>
							
							<Row className="mt-4" >
								<label className="col-md-2 col-form-label" >Gender <span className="text-danger">*</span> </label>
								<Col md={4} >
									<Form.Control
										as="select" 
										size="md"
										name="gender"
										required
									>
										<option value=""> Select </option>
										<option value="male" > Male </option>
										<option value="female" > Female </option>
									</Form.Control>
								</Col>
								<label className="col-md-2 col-form-label" >Father Name <span className="text-danger">*</span> </label>
								<Col md={4} >
									<Form.Control
										type="text" 
										size="md"
										name="father_name"
										placeholder="Name of the Father in English"
										className="text-uppercase"
										onKeyPress={nameWithDotAndHypen} 
										required
									/>
								</Col>
							</Row>
							
							<Row className="mt-1" >
								<label className="col-md-2 col-form-label" >Aadhar No <span className="text-danger">*</span> </label>
								<Col md={4} >
									<Form.Control
										type="text" 
										size="md"
										name="aadhar_no"
										placeholder="Aadhar No"
										onKeyPress={integerAadhar} 
										onChange={(e)=> setAadhar(e.target.value)}
										required
									/>
								</Col>
							</Row>
							
							<Row className="mt-1" >
								<label className="col-md-2 col-form-label" >Date of birth <span className="text-danger">*</span> </label>
								<Col md={4} >
									<Form.Control
										type="date" 
										size="md"
										name="dob"
										max={getToday()}
										required
									/>
								</Col>
								<label className="col-md-2 col-form-label" >Place of birth <span className="text-danger">*</span> </label>
								<Col md={4} >
									<Form.Control
										type="text" 
										size="md"
										name="place_of_birth"
										placeholder="Place of Birth"
										required
									/>
								</Col>
							</Row>
							
							<Row className="mt-2" >
								<label className="col-md-2" >Date of Baptism <span className="text-danger">*</span> </label>
								<Col md={4} >
									<Form.Control
										type="date" 
										size="md"
										name="baptism_date"
										//max={getToday()}
										required
									/>
								</Col>
								<label className="col-md-2 " >Place of Baptism <span className="text-danger">*</span> </label>
								<Col md={4} >
									
									<Form.Control
										type="text" 
										size="md"
										name="place_of_baptism"
										placeholder="Place of Baptism"
										required
									/>
									
								</Col>
							</Row>
							
							<Row className="mt-2" >
								<label className="col-md-2" >Date of Confirmation <span className="text-danger">*</span> </label>
								<Col md={4} >
									<Form.Control
										type="date" 
										size="md"
										name="confirmation_date"
										required
									/>
								</Col>
								<label className="col-md-2 " >Place of Confirmation <span className="text-danger">*</span> </label>
								<Col md={4} >
									<Form.Control
										type="text" 
										size="md"
										name="place_of_confirmation"
										placeholder="Place of Confirmation"
										required
									/>
									<ButtonGroup className="mt-1" >
										<Button
											value="pastorate"
											size="sm"
											type="button"
											variant={baptimsAt=='pastorate' ? 'secondary' : 'outline-secondary'}
											name="baptimsAt"
											onClick={e=> setBaptismAt(e.target.value)}
										>
										Pastorate
										</Button>
										<Button
											value="sabai"
											size="sm"
											type="button"
											variant={baptimsAt=='sabai' ? 'secondary' : 'outline-secondary'}
											name="baptimsAt"
											onClick={e=> setBaptismAt(e.target.value)}
										>
										Sabai
										</Button>
									</ButtonGroup>
								</Col>
							</Row>
							
							<Row className="mt-2" >
								<label className="col-md-2" >First Holy Communion <span className="text-danger">*</span> </label>
								<Col md={4} >
									<Form.Control
										type="date" 
										size="md"
										name="first_communion"
										required
									/>
								</Col>
							</Row>
							
							<Row className="mt-4" >
								<Col md={5} >
									<Alert size="sm" variant="primary" >
										Fields marked with (<span className="text-danger">*</span>) is required
									</Alert>
								</Col>
							</Row>
								
								
								</Col>
								<Col md={2} >
									<label className="col-form-label" >Photo <span className="text-danger">*</span> </label>
									<img src={newFile} style={styles.imgStyle} />
									<label for="hb_file-upload" className="btn btn-default btn-xs mt-1">
										<i className="icofont-search pe-1 me-1 border-right" ></i>Select
									</label>
									<input type="file" name="mem_photo" id="hb_file-upload" onChange={handleSelectFiles} accept="image/*" />
									<Button
										type="button"
										size="xs"
										variant="default"
										onClick={()=> removeFile() }
									>
										<i className="icofont-close pe-1 me-1 border-right" ></i>Remove
									</Button>
								</Col>
							</Row>
							
							</div>
							
							<div className="panel-footer" >
								<Row>
									<Col md={8} >
										
									</Col>
									<Col md={4} >
										<div className="text-right" >
											<Button
												type="submit"
												size="sm"
												variant="default"
												disabled={loader}
											>
											{loader ? <>
												<Spinner animation="border" size="sm" /> <span className="ps-2 border-left">WAIT..</span>
											</> : <>
												<i className="icofont-check pe-2 border-right" ></i> SAVE
											</>}
											</Button>
										</div>
									</Col>
									
									
								</Row>
							</div>
						
						
					</Form>
				
				</div>
				
			</Col>
		</Row>
	</>
	);
};

export default AddMembershipCard;