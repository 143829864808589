import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import { Api, copyRight } from '../../../utils/Variables';
import axios from 'axios';
import {Row, Col, Card, Carousel} from 'react-bootstrap';

import PsContext from '../../../context/PsContext';

import LoginForm from './LoginForm';
	
class Login extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
		
	}
	
	
		
	render(){
		
		if(this.context.state.isLogged=='YES'){
			if(this.context.state.loggedUser.role){
				return(<Redirect to={"/"+this.context.state.loggedUser.role.toLowerCase()} />);
			}
			return(<Redirect to="/app" />);
		}
		
		return(
			<div className="login-container  " >
				<div className="container pt-50 " >
					<Row >
						<Col md={{span: 6, offset: 3 }} >	
							{/*<b className="font-weight-600 font-40" >
							<img src={HL} className="llogo" />
							Hosaid</b>
							<Card className="shadow-lg px-30 pb-30 pt-20" >
								<Card.Body>
									<b className="font-25 " >Sign In</b>
									<div className="mt-30" >
										<b>User credentials</b>
									</div>
									<LoginForm />	
								</Card.Body>
							</Card>*/}
							
							<div className="panel panel-form">
								<div className="panel-header">
									LOGIN
								</div>
								<div className="panel-body" style={{ height: 'auto' }} >
									<div className="px-50 py-30">
									{/*<div className="text-center">
											<img src={HL} />
									</div>*/}
										
										<LoginForm />	
									</div>
								</div>
								<div className="panel-footer">
									<Row>
										<Col md={6}>
											Developed by <a href="https://peacesoft.in" target="_blank">Peace Soft</a>
										</Col>
										<Col md={6} >
											<div className="text-right">
												{copyRight.version} &copy; {copyRight.year} 
											</div>
										</Col>
										<Col md={12}>
											<div className="mt-10" >
												<i className="icofont-phone"></i> {copyRight.mobile} &emsp;
												<i className="icofont-email"></i> {copyRight.email} 
											</div>
										</Col>
									</Row>
								</div>
							</div>
							
						</Col>
					</Row>
				</div>
			</div>
		);
		
		/*return(
			<div className="section mt-50" >
				<div className="container" >
					<Row>
						<Col md={{span:10, offset: 1}} >
							<Card>
								<Card.Body className="px-0 py-0" >
									<Row>
										<Col md={8} >
											<Carousel>
											  <Carousel.Item>
												<img
												  className="d-block w-100"
												  src="https://static.vecteezy.com/system/resources/previews/000/549/627/non_2x/vector-abstract-technology-background-technology-digital-world-of-business-information-futuristic-blue-virtual-graphic-interface.jpg"
												  alt="First slide"
												/>
											 </Carousel.Item>
											</Carousel>
										</Col>
										<Col md={4} >
											<div className="pr-25 pt-25" >
												<LoginForm />	
											</div>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Col>				  
					</Row>
				</div>
			</div>
		);*/
	}
	
}
export default Login;
